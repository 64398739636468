import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ActionItem } from '../../models/CategoryItem';
import { getUserInfo } from '@igps/client-root-config';
import { ActionOption } from '../../enums/action-option';
import { EMPTY, empty, finalize, forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { ActionService } from 'src/app/services/action.service';
import { Assignee } from 'src/app/models/Assignee';
import { UserInfo } from 'src/app/models/UserInfo';
import { ActivatedRoute, Router } from '@angular/router';
import { SourceApp, TaskBasicInfo } from 'src/app/models/taskBasicInfo';
import { GetTaskBasicInfo, ReloadTaskBasicInfoEvent, TaskBasicInfoEvent } from '@igps/file-info-header';
import { ToastComponent } from '@ui-core/toast';
import { MacrosResponse } from 'src/app/models/ActionResponse';
import { UtilityService } from 'src/app/services/utility.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
export const refreshActions: EventEmitter<void> = new EventEmitter<void>();

@Component({
  selector: 'app-action-section',
  templateUrl: './action-section.component.html',
  styleUrls: ['./action-section.component.scss']
})
export class ActionSectionComponent implements OnInit {
  title = 'IGPS-Client-ActionsPopup-UI';
  selectedAction: number | undefined;
  actionOption = ActionOption;
  isError!: boolean;
  errorMessage!: string;
  @ViewChild(ToastComponent) toaster!: ToastComponent;
  actions: string[] = [];
  userinfo!: UserInfo;
  enableSubmit: boolean = false;
  showActionSec: boolean = false;
  actionId!: number;
  actionItem!: ActionItem[];
  staffList!: Assignee[];
  taskId$!: Observable<string>;
  taskInformationSubscription$!: Subscription;
  RefreshActionSubscription!: Subscription;
  basicInfo!: TaskBasicInfo;
  isDialOpen!: Subscription;
  constructor(private commonService: CommonService, private actionService: ActionService, private _route: ActivatedRoute, private cdref: ChangeDetectorRef, private utilityservice: UtilityService, private zone: NgZone, private router: Router, private location: Location) {
    this.actions = Object.keys(this.actionOption).filter(f => !isNaN(Number(f)));
  }

  ngOnInit(): void {
    this.userinfo = getUserInfo();
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe(data => {
      this.basicInfo = data.taskinfo;
      this.loadActions();
    });
    if (!this.basicInfo) {
      this.basicInfo = GetTaskBasicInfo();
      if (this.basicInfo) {
        this.loadActions();
      }
    }
    this.RefreshActionSubscription = refreshActions.subscribe(() => {
      this.selectedAction = undefined;
      this.loadActions();
    });
  }
  openAction() {
    this.showAction(this.selectedAction!);
  }

  loadActions() {
    if (this.userinfo) {
      if (this.userinfo.id === this.basicInfo.assignee?.id && this.basicInfo.taskStatus.value != "Branch"
        && this.basicInfo.taskStatus.value != "Cancelled" && this.basicInfo.taskStatus.value != "Completed" && this.basicInfo.taskStatus.value != "Completed w/ Exception") {
        this.enableSubmit = true;
      }
      else {
        this.enableSubmit = false;
      }
      ///Actions List///    
      this.actionItem = [
        { value: ActionOption.COMPLETE, action: 'Complete' },
        { value: ActionOption.COMPLETE_WITH_ERRORS, action: 'Complete w/Exception' },
        { value: ActionOption.PUT_ON_HOLD, action: 'Put On Hold' },
        { value: ActionOption.RETURN_TO_BRANCH, action: 'Return to Branch' },
        { value: ActionOption.SEND_TO_ON_QUERY, action: 'Send to Query' }
      ];

      if (this.basicInfo.taskStatus.value === 'On Hold') {
        this.actionItem = [
          { value: ActionOption.REMOVE_HOLD, action: 'Remove hold' }
        ];
      }
      else {
        if (this.userinfo.role.id === 1000) {
          this.actionItem.push({ value: ActionOption.SEND_TO_PROCESSOR, action: 'Send to Processor' });
          if (this.basicInfo.processHasOnshoreQueue) {
            this.actionItem.push({ value: ActionOption.ESCALATE_TO_ONSHORE, action: 'Escalate to Onshore' });
          }
          if (this.basicInfo?.assignee != null) {
            this.enableSubmit = true;
          }
          if (this.basicInfo.emailId !== null) //To check whether task is linked to an email or not
          {
            this.actionItem.push({ value: ActionOption.UNLINK_TASK, action: 'Unlink Task' });
          }
          if (this.basicInfo.sourceId === SourceApp.IgnitePS) {
            this.actionItem.push({ value: ActionOption.CANCEL, action: 'Cancel' });
            if (this.basicInfo.taskStatus.value === 'Branch') {
              this.enableSubmit = true;
              this.actionItem = [{ value: ActionOption.CANCEL, action: 'Cancel' }];
            }
          }
        }
        if (this.userinfo.role.id === 250) {
          if (this.basicInfo.sourceId === SourceApp.IgnitePS) {
            this.actionItem.push({ value: ActionOption.CANCEL, action: 'Cancel' });
          }
          if (this.basicInfo.emailId !== null) //To check whether task is linked to an email or not
          {
            this.actionItem.push({ value: ActionOption.UNLINK_TASK, action: 'Unlink Task' });
          }
        }

        if (this.userinfo.role.id === 750 || this.userinfo.role.id === 500) {
          if (this.basicInfo.taskStatus.value != 'On Hold') {
            if (this.basicInfo.processHasOnshoreQueue == true) {
              this.actionItem.push(
                { value: ActionOption.ESCALATE_TO_ONSHORE, action: 'Escalate to Onshore' });
            }
            this.actionItem.push(
              { value: ActionOption.SEND_TO_PROCESSOR, action: 'Send to Processor' }
            );
            if (this.basicInfo.sourceId === SourceApp.IgnitePS) {
              this.actionItem.push({ value: ActionOption.CANCEL, action: 'Cancel' });
            }
          }
          if (this.basicInfo.emailId !== null) //To check whether task is linked to an email or not
          {
            this.actionItem.push({ value: ActionOption.UNLINK_TASK, action: 'Unlink Task' });
          }
          if (this.basicInfo.taskStatus.value === 'Branch') {
            if (this.basicInfo.sourceId === SourceApp.IgnitePS) {
              this.enableSubmit = true;
              this.actionItem = [{ value: ActionOption.CANCEL, action: 'Cancel' }];
            }
          }
        }
      }
      this.actionItem = this.actionItem.sort((a, b) => a.action.localeCompare(b.action));
      this.cdref.detectChanges();
    }
  }

  onActionChange() {
    let selectedActionObj = this.actionItem.find(a => a.value == this.selectedAction) as { value: number, action: string };
    this.actionId = selectedActionObj.value;


    this.showAction(this.actionId!);
    this.cdref.detectChanges();
  }

  public showAction(actionId: number): void {
    let actionName = this.actionItem.find(a => a.value == actionId)?.action as string;
    if (this.actionId === ActionOption.REMOVE_HOLD) {
      const addAction: MacrosResponse = {
        MacroIds: [],
        Note: ''
      };
      this.utilityservice.setSpinner(true);
      this.actionService.submitAction(addAction, this.basicInfo.id, this.actionId).pipe(finalize(() => {
        this.utilityservice.setSpinner(false);
      }))
        .subscribe({
          next: () => {
            this.utilityservice.showToast.emit({ message: 'Submitted successfully', isError: false });
            ReloadTaskBasicInfoEvent.emit();
          },
          error: (error: HttpErrorResponse) => {
            if (error?.error.Status !== 409) {
              this.utilityservice.showToast.emit({ message: 'Error submitting', isError: true });
            } else {
              this.utilityservice.showToast.emit({ message: 'Error occurred', isError: true });
            }
          }
        });     
    }
    else {
      this.zone.run(() => {
        const dialogRef = this.commonService.openActionDialog(actionName, this.actionId, this.basicInfo, this.basicInfo.processId);
        dialogRef.afterClosed().subscribe(() => {
          this.selectedAction = undefined;
        });
      });

    }

  }

  ngOnDestroy(): void {
    this.taskInformationSubscription$?.unsubscribe();
    this.RefreshActionSubscription?.unsubscribe();
  }
  openReportError() {
    const dialogRef = this.commonService.openErrorDialog(this.basicInfo, this.userinfo);
    dialogRef.beforeClosed().subscribe((result) => {
      if (result === 'error') {
        this.openToast('Error occurred', true);
      }
      if (result === 'success') {
        this.openToast('Success', false);
      }
    });
  }
  openToast(message: string, isError: boolean) {
    this.isError = isError;
    this.errorMessage = message;
    this.toaster.toasterTimeout();
  }

}
