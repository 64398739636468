<div mat-dialog-title class="mat-dialog-title">
    <div>{{ data.heading }}</div>
    <button mat-button mat-dialog-close (click)='close(" ")'>
        <img src="/assets/icons/icon-close-dark.svg" alt="calendar icon">
    </button>
</div>
<mat-dialog-content class="mat-typography mat-dialog-content">
    <p [innerHtml]="data.message"></p>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions" align="end" *ngIf="data.align == 'end'">
    <button *ngIf="data.size && data.size === 'small' && data.labels[0]" mat-button mat-dialog-close
        class="ig2 button button--secondary button--small mat-button mat-button-base"
        (click)='close(data.labels[0])'>{{data.labels[0]}}</button>
    <button *ngIf="!data.size || (data.size && data.size !== 'small') && data.labels[0]" mat-button mat-dialog-close
        class="ig2 button button--no-border button--small mat-button mat-button-base"
        (click)='close(data.labels[0])'>{{data.labels[0]}}</button>
    <button *ngIf="data.labels[1]" mat-flat-button
        class="ig2 button button--primary button--small mat-button mat-button-base" (click)='close(data.labels[1])'
        cdkFocusInitial>{{data.labels[1]}}</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="data.align == 'between'">
    <button mat-button mat-dialog-close class="ig2 button button--no-border button--small mat-button mat-button-base"
        (click)='close(data.labels[0])'>{{data.labels[0]}}</button>
    <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base"
        (click)='close(data.labels[1])' cdkFocusInitial>{{data.labels[1]}}</button>
</mat-dialog-actions>
<mat-dialog-actions class="mat-dialog-actions" align="end" *ngIf="data.align == 'nofile'">
    <button mat-flat-button mat-dialog-close
        class="ig2 button button--secondary button--small mat-button mat-button-base btn-padding"
        (click)='close(data.labels[0])'>{{data.labels[0]}}</button>
    <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base"
        (click)='close(data.labels[1])' cdkFocusInitial>{{data.labels[1]}}</button>
</mat-dialog-actions>