import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorInfoDialogComponent } from '../components/error-info-dialog/error-info-dialog.component';
import { OpenDialogComponent } from '../components/open-dialog/open-dialog.component';
import { IgpsMessageComponent } from '../components/shared/igps-message/igps-message.component';
import { TaskBasicInfo } from '../models/taskBasicInfo';
import { UserInfo } from '../models/UserInfo';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    userinfo!: UserInfo;

    constructor(private matDialog: MatDialog) { }

    public openActionDialog(selectedAction: string, actionId: number, taskInfo: TaskBasicInfo, processId: string) {
        return this.matDialog.open(OpenDialogComponent, {
            panelClass: ['ig2','custom-mat-containter'],
            data: {
                actionName: selectedAction,
                actionId: actionId,
                taskInfo: taskInfo,
                processId: processId
            },
            disableClose: true,
        });
    }
    public openWarningDialog(wMessage: string, header = 'Warning', labels = ['No', 'Yes']) {
        return this.matDialog.open(IgpsMessageComponent, {
            panelClass: ['ig2', 'mat-dialog-container-small'],
            data: {
                heading: header,
                message: wMessage,
                labels,
                size: 'small',
                align: 'end',
            },
            disableClose: true,
        });
    }
    public openErrorDialog(taskInfo: TaskBasicInfo , userinfo : UserInfo ) {
        return this.matDialog.open(ErrorInfoDialogComponent, {
            panelClass: ['ig2', 'mat-dialog-container-large'],
            data: {
                heading: "Report an Error",
                size: 'large',
                align: 'end',
                taskInfo: taskInfo,
                userinfo: userinfo
            },
            disableClose: true,
        });
    }
}
