import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-igps-message',
  templateUrl: './igps-message.component.html',
  styleUrls: ['./igps-message.component.scss']
})
export class IgpsMessageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<IgpsMessageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      heading: string;
      message: string;
      labels: string[];
      size: string;
      align: string;
    }
  ) { }

  close(message: string): void {
    this.dialogRef.close(message.toLowerCase());
  }
  
  ngOnInit(): void {
  }

  

}
