export enum CommonMessages {
    Success = 'Your changes are saved',
    Error = 'Your changes are not saved',
    NoChanges='No changes to save',
    UnsavedChangesMessage = 'You have unsaved work that has not been submitted. Would you like to continue?',
    DeactivateRecordMessage='Deactivating the record will deactivate all the linked records. Do you want to go ahead with the same?',
    IGPSApiServerErrorMessage = 'Internal server error in IGPS API. Please re-try or report the incident if the issue persists.',
    InternalServerErrorMessage = 'Internal server error. Please re-try or report the incident if the issue persists.',
    ExceptionMessage = 'Server operation failed. Please re-try or report the incident if the issue persists.',
    TextInfoMessage = 'Process Name',
    DataChangeMessage = 'You may get error, as selected Region or Type is different than with loaded data.',
    SearchInputPlaceholder = 'Search by File Number, Property Address, Mortgagor Name, Loan Number',
    UnlinkTaskForIgniteMessage = 'Are you sure you want to unlink this task?',
    UnlinkTaskForIgnitePSMessage = 'This task will be cancelled and a new task must be created if needed. Do you want to proceed?',
    CancelledTaskMessage = 'Are you sure you want to cancel? Both the email and task will be removed from the system.'
}