// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[0].use[2]!../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
.mat-dialog-container {
  width: 100% !important;
}

.mat-dialog-container-large {
  width: 1000px;
}

.custom-mat-containter .mat-dialog-title {
  padding-right: 20px !important;
}
.custom-mat-containter .mat-dialog-content {
  overflow: visible !important;
}

mat-form-field.ig2.mat-form-field {
  margin: 16px 8px 16px 0;
  max-width: 200px;
}

.category-row .custom-select {
  width: 180px !important;
}
.category-row .sub-category-dd mat-form-field {
  margin-right: 0px;
}

.macro-select {
  max-height: 350px !important;
  min-width: 350px !important;
  position: relative;
  left: 30px;
  top: 25px;
}
.macro-select .options {
  max-height: 258px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AAGA;EACI,sBAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAII;EACI,8BAAA;AADR;AAGI;EACI,4BAAA;AADR;;AAOQ;EACI,uBAAA;EACA,gBAAA;AAJZ;;AAUE;EACE,uBAAA;AAPJ;AAUI;EACE,iBAAA;AARN;;AAaA;EAIE,4BAAA;EACA,2BAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;AAbF;AAME;EACE,4BAAA;AAJJ","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n\n@import '@angular/material/prebuilt-themes/deeppurple-amber.css';\n.mat-dialog-container {\n    width: 100% !important;\n}\n\n.mat-dialog-container-large {\n    width: 1000px;\n}\n\n.custom-mat-containter {\n    .mat-dialog-title {\n        padding-right: 20px!important;\n    }\n    .mat-dialog-content {\n        overflow: visible!important;\n    }\n}\n\nmat-form-field {\n    &.ig2 {\n        &.mat-form-field {\n            margin: 16px 8px 16px 0;\n            max-width: 200px;\n        }\n    }\n}\n\n.category-row{\n  .custom-select{\n    width: 180px !important;\n  }\n  .sub-category-dd{\n    mat-form-field{\n      margin-right: 0px;\n    }\n  }\n}\n\n.macro-select {\n  .options {\n    max-height: 258px !important;\n  }\n  max-height: 350px !important;\n  min-width: 350px !important;\n  position: relative;\n  left: 30px;\n  top: 25px;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
