import { Injectable } from "@angular/core";
import { map, merge, mergeMap, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MacrosResponse } from "../models/ActionResponse";
import { MacroModel, MacroSubCategory } from "../models/CategoryItem";
import { EmailModel } from "../models/EmailModel";

@Injectable({
  providedIn: 'root'
})

export class ActionService {

  constructor(private httpClient: HttpClient) { }
  readonly domainUrl = `${environment.igps.apiUrl}`;
  readonly baseUrl = `${environment.igpsBase.apiUrl}`;
  readonly emailUrl = `${environment.igpsBase.emailUrl}`;

  getMacroSubCategory(actionId: number): Observable<MacroSubCategory[]> {
    const filters: {Field: string; Value: string}[] = [
      {
        Field: "Status.Id",
        Value: "2 OR 3 OR 4"
      },
      {
        Field: "ActionId",
        Value: actionId.toString()
      }
    ];
    return this.httpClient.post<{
      count: number;
      items: MacroSubCategory[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macrosubcategory/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: 1,
        PageSize: 50,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => {
        if(resp.pageSize < resp.totalItemCount){
          let observables: Observable<MacroSubCategory[]>[] = [];
          observables.push(of(resp.items));
          const noofapicalls: number = (Math.ceil(resp.totalItemCount/resp.pageSize) - 1);
          for (let index = 1; index <= noofapicalls; index++) {
            observables.push(this.getMacroSubCategories(index+1, resp.pageSize, filters));
          }
          return merge(...observables);
        }
        return of(resp.items);
      }),
      mergeMap(d => d)
    );
  }

  private getMacroSubCategories(pageno: number, pagesize: number, filters: {Field: string; Value: string}[]): Observable<MacroSubCategory[]>{
    return this.httpClient.post<{
      count: number;
      items: MacroSubCategory[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macrosubcategory/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: pageno,
        PageSize: pagesize,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => resp.items)
    );
  }

  getMacro(actionId: number, productionProcessId: string, isOnshore: boolean): Observable<MacroModel[]> {
    const filters: {Field: string; Value: string}[] = [
      {
        Field: "Status.Id",
        Value: "1"
      },
      {
        Field: "ProcessList.ProcessId.keyword",
        Value: productionProcessId
      },
      {
        Field: "ActionId",
        Value: actionId.toString()
      }
    ];
    if(isOnshore){
      filters.push({
        Field: "HasOnshoreQueue",
        Value: "true"
      });
    }
    else{
      filters.push({
        Field: "HasOffshoreQueue",
        Value: "true"
      });
    }
    return this.httpClient.post<{
      count: number;
      items: MacroModel[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macro/v1/clientdropdownsearch`, {
      PageRequest: {
        PageNumber: 1,
        PageSize: 20,
      },
      SortRequest: {
        Field: 'MacroName.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => {
        if(resp.pageSize < resp.totalItemCount){
          let observables: Observable<MacroModel[]>[] = [];
          observables.push(of(resp.items));
          const noofapicalls: number = (Math.ceil(resp.totalItemCount/resp.pageSize) - 1);
          for (let index = 1; index <= noofapicalls; index++) {
            observables.push(this.getMacros(index+1, resp.pageSize, filters));
          }
          return merge(...observables);
        }
        return of(resp.items);
      }),
      mergeMap(d => d)
    );
  }

  private getMacros(pageno: number, pagesize: number, filters: {Field: string; Value: string}[]): Observable<MacroModel[]>{
    return this.httpClient.post<{
      count: number;
      items: MacroModel[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macro/v1/clientdropdownsearch`, {
      PageRequest: {
        PageNumber: pageno,
        PageSize: pagesize,
      },
      SortRequest: {
        Field: 'MacroName.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => resp.items)
    );
  }

  getTaskInformation(taskId: string) {
    return this.httpClient.get(`${this.baseUrl}` + `gentask/gentask/v1/task/` + `${taskId}` + `/basicinfo`);
  }

  getStaffNames(processId: string) {
    return this.httpClient.get(`${this.baseUrl}` + `staffing/staff/v1/process/` + `${processId}` + `/dropdown`)
  }

  submitAction(macroItem: MacrosResponse, taskid: string, actionid: number) {
    return this.httpClient.post(`${this.baseUrl}` + `gentask/gentask/v1/task/` + `${taskid}` + '/action/' + `${actionid}`, macroItem);
  } 
  UnAssign(taskId: string) {
    return this.httpClient.post(`${this.baseUrl}` + `gentask/gentask/v1/task/` + `${taskId}` + `/unassign`, null);
  }
  
  getEmailDetails(emailMessageId: string): Observable<EmailModel>{
    return this.httpClient.get<EmailModel>(`${this.emailUrl}/emaildetail/${emailMessageId}`);
  }
}
