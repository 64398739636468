<article class="dialog__header">
    <h3 mat-dialog-title>{{data.title}}</h3>
    <!-- <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" /> -->
</article>
<mat-dialog-content class="mat-typography ignite-two">
    <p> {{data.message}} </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="ig2 button button--secondary button--small mat-button mat-button-base"
        value="No" [mat-dialog-close]="false"> No </button>
    <button mat-flat-button value="Yes" [mat-dialog-close]="true"
        class="ig2 button button--primary button--small mat-button mat-button-base" cdkFocusInitial> Yes </button>
</mat-dialog-actions>