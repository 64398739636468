import { getUserInfo } from '@igps/client-root-config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { NavigateBackEventConfirm, NavigateBackEventStart, NavigateBackEventWait } from '@igps/file-info-header';

@Component({
  selector: 'app-back-to-dashboard',
  templateUrl: './back-to-dashboard.component.html',
  styleUrls: ['./back-to-dashboard.component.scss']
})
export class BackToDashboardComponent implements OnInit, OnDestroy {

  NavigateBackEventWaitSubscription!: Subscription;
  NavigateBackEventConfirmSubscription!: Subscription;
  timerSubscription!: Subscription;


  constructor(private router: Router) { }

  ngOnInit(): void {
    this.NavigateBackEventWaitSubscription = NavigateBackEventWait.subscribe(() => {
      if(this.timerSubscription){
        this.timerSubscription.unsubscribe();
      }
    });
    this.NavigateBackEventConfirmSubscription = NavigateBackEventConfirm.subscribe(() => {
      if(this.timerSubscription){
        this.timerSubscription.unsubscribe();
      }
      this.navigate();
    });
  }

  ngOnDestroy(): void {
    this.NavigateBackEventWaitSubscription.unsubscribe();
    this.NavigateBackEventConfirmSubscription.unsubscribe();
  }

  onBack(){
    this.timerSubscription = timer(500).subscribe(() => this.navigate());
    NavigateBackEventStart.emit();
  }

  navigate(){
    const userInfo = getUserInfo();
    if(userInfo.assumedRoleId == 1){
      this.router.navigate(['manager/dashboard']);
    }
    else{
      this.router.navigate(['processor/dashboard']);
    }
  }

}
