export enum ActionOption {
    RETURN_TO_BRANCH = 1,
    COMPLETE = 2,
    COMPLETE_WITH_ERRORS = 3,
    START_TASK = 4,
    SEND_TO_ON_QUERY = 5,
    PUT_ON_HOLD = 6,
    REMOVE_HOLD = 7,
    ESCALATE_TO_ONSHORE = 8,
    SEND_TO_PROCESSOR = 9,
    CANCEL = 10,
    UNLINK_TASK = 14
  }