<lib-toast></lib-toast>
<div mat-dialog-title class="mat-dialog-title">
    <div>{{ data.heading }}</div>
    <img mat-dialog-close class="dialog__header-icon" style="width: 29px;padding-right: 12px;"
        src="assets/icons/icon-close-dark.svg" alt="close icon" />
</div>
<form autocomplete="off" novalidate (ngSubmit)="createerror(errorForm.value)">

    <mat-dialog-content [formGroup]="errorForm" class="mat-typography mat-dialog-content">
        <div>
            <h4 class="header">Reported Errors</h4>
            <div class="table-container ">
                <table aria-hidden="true">
                    <tr>
                        <th *ngFor="let column of this.headers">
                            {{column}}
                        </th>
                    </tr>

                    <tr *ngFor="let row of this.apiresult">
                        <td *ngFor="let column of this.properyNames">
                            {{column != 'timeReported' ? row[column] : row[column] | convertTime: 'utc' : timezone.value
                            }}
                        </td>

                    </tr>
                </table>


                <div class="label-input-labelwrapper" style="padding-top: 25px;width: 257px;">

                    <reactive-filter-select id="selectProcess" class="ig2" label="Select Step (Optional)"
                        placeholder="Select from the list" [options]="ProcessList" formControlName="process">
                    </reactive-filter-select>
                </div>

                <div class="user-row notes">
                    <div class="label-input-labelwrapper twocolumn-text">
                        <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
                            <mat-label class="mat-label-wrap">Comment
                            </mat-label>
                            <textarea maxlength="1000" id="comments" formControlName="comments" #comments matInput
                                cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"></textarea>

                        </mat-form-field>
                    </div>

                </div>
                <div>
                    <p>{{ comments ? maxLengthAllowedChars - comments.value.length : maxLengthAllowedChars }} character
                        is remaining</p>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="ig2 button button--no-border button--small cancelButton" mat-button
            mat-dialog-close>Cancel</button>

        <button class="mat-focus-indicator ig2 button button--primary manage-widget-button mat-button mat-button-base"
            mat-button [disabled]=!this.errorForm.valid (click)="update()" cdkFocusInitial>
            Submit
        </button>
    </mat-dialog-actions>
</form>