import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { CommonMessages } from '@igps/root-config';
import { CommonService } from "../services/common.service";
export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean> | Promise<boolean> | void;
  }

  @Injectable()
  export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
   // commonMessages = CommonMessages;
    readonly UNSAVED_CHANGES_DIALOG_MESSAGE = 'Changes unsaved';
    constructor(private commonService: CommonService) {}

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
      // if there are no pending changes, just allow deactivation; else confirm first
      if (!component || component.canDeactivate()) {
        return true;
      } else {
        return new Observable((obs) => {
          this.commonService
            .openWarningDialog(this.UNSAVED_CHANGES_DIALOG_MESSAGE)
            .beforeClosed()
            .subscribe((result: any) => {
              if (result === 'yes') {
                return obs.next(true);
              } else {
                return obs.next(false);
              }
            });
        });
      }
    }
  }