import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorInfoData, ReportErrorItem } from '../models/errorInfoModel';
import { PagingModel } from '../models/PagingModel';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {

  readonly domainUrl = `${environment.igps.apiUrl}`;
  readonly baseUrl = `${environment.igpsBase.apiUrl}`;
  readonly apiurl = `${environment.igpsBase.gentaskurl}`;
  constructor(private httpClient: HttpClient) { }
  readonly pageInfo: PagingModel = {
    PageRequest: {
      PageNumber: 1,
      PageSize: 1000,
    },
    SortRequest: {
      Field: 'Name.keyword',
      Direction: 1,
    },
    Filters: [],
  };

  async getAllProcesses(productionSystemName:string,isSuperAdmin:boolean): Promise<any> {
    if (!isSuperAdmin){
    this.pageInfo.Filters?.push(
      { Field: "ProductionSystemId.keyword", Value: productionSystemName, Operator: 1, DataType:2 }
    )
    }
    return await firstValueFrom(this.httpClient.post(`${this.domainUrl}` + `search/prdprocess/v1/search`, this.pageInfo));
  }
  async getAllError(taskid: string): Promise<ErrorInfoData[]> {
    return await firstValueFrom(this.httpClient.get<ErrorInfoData[]>(`${this.apiurl}` + `error/v1/task/${taskid}`));
  }
  createErrorItem(reportErrorItem: ReportErrorItem) {
    return this.httpClient.post(`${this.apiurl}` + `error/v1/task/${reportErrorItem.id}`, {Notes:reportErrorItem.Comments,ProcessID: reportErrorItem.ProcessID});

  }
}
