import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReloadTaskBasicInfoEvent } from '@igps/file-info-header';
import { ToastComponent } from '@ui-core/toast';
import { Subscription } from 'rxjs';
import { CommonMessages } from 'src/app/enums/CommonMessages';
import { CommonDataModel } from 'src/app/models/CommonDataModel';
import { ReportErrorItem } from 'src/app/models/errorInfoModel';
import { PagingModel } from 'src/app/models/PagingModel';
import { TaskBasicInfo } from 'src/app/models/taskBasicInfo';
import { UserInfo } from 'src/app/models/UserInfo';
import { ErrorNotificationService } from 'src/app/services/error-notification.service';
import { UtilityService } from 'src/app/services/utility.service';
import { getTimezone, timezone$ } from '@igps/client-root-config';

@Component({
  selector: 'app-error-info-dialog',
  templateUrl: './error-info-dialog.component.html',
  styleUrls: ['./error-info-dialog.component.scss']
})
export class ErrorInfoDialogComponent implements OnInit, OnDestroy {
  @ViewChild(ToastComponent) toaster!: ToastComponent;
  isError!: boolean;
  errorMessage!: string;
  taskid!: string;
  selectedProcessId!: string;
  ProcessList!: CommonDataModel[];
  headers!: any[];
  properyNames!: any[];
  public errorForm!: FormGroup;
  public initalValues: any;
  hasChange!: boolean;
  showSpinner: boolean = false;
  public maxLengthAllowedChars = 1000;
  isSuperAdmin!: boolean;
  pageInfo: PagingModel = {
    PageRequest: {
      PageNumber: 1,
      PageSize: 1000,
    },
    SortRequest: {
      Field: 'Name.keyword',
      Direction: 1,
    },
    Filters: [],
  };
  apiresult!: any[];
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };

  constructor(private formBuilder: FormBuilder, private errorNotificationService: ErrorNotificationService, private utilityService: UtilityService,
    public dialogRef: MatDialogRef<ErrorInfoDialogComponent>, private cdref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      heading: string;
      message: string;
      taskInfo: TaskBasicInfo;
      labels: string[];
      size: string;
      align: string;
      config: any;
      userinfo: UserInfo
    }
  ) { }

  async ngOnInit(): Promise<void> {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
    });
    this.utilityService.setSpinner(true);
    this.taskid = this.data.taskInfo.id;
    this.selectedProcessId = this.data.taskInfo.processId;
    this.headers = ["Error Occured During", "Error Comments", "Time Reported", "Reported By"];
    this.properyNames = ["errorOccuredDuring", "errorComments", "timeReported", "reportedBy"];
    await this.initializeForm();
    await this.getAllErrorInfos();
    this.isSuperAdmin = this.data.userinfo?.role?.name === "SUPERADMIN" ? true : false;
    const processListTemp = await this.errorNotificationService.getAllProcesses(this.data.userinfo.productionSystem.id, this.isSuperAdmin);
    this.ProcessList = processListTemp?.items as CommonDataModel[];

    this.errorForm.controls["process"].setValue(this.selectedProcessId);
    this.utilityService.setSpinner(false);
    this.cdref.detectChanges();
  }

  async initializeForm() {
    this.errorForm = this.formBuilder.group(
      {
        comments: ['', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(1000)
        ]],
        process: null,
        id: ['']
      });
    this.initalValues = this.errorForm.value;

    this.errorForm.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(this.initalValues).some(key => this.errorForm.value[key] !== this.initalValues[key])
    });

  }

  async getAllErrorInfos() {
    await this.errorNotificationService
      .getAllError(this.taskid).then((data) => {
        this.apiresult = data;
      }).catch(() => {
      }).finally(() => {
        this.cdref.detectChanges();
      })
  }
  update() {
    ////console.log(this.errorForm.valid);

  }
  close(message: string): void {
    this.dialogRef.close(message.toLowerCase());
  }
  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
  }
  public createerror = (_errorForm: any) => {
    if (this.errorForm.valid) {
      if (!this.hasChange) {
        this.openToast(CommonMessages.NoChanges, false)
      }
      else {
        this.utilityService.setSpinner(true);
        const addErrorNotificationService: ReportErrorItem = {
          Comments: _errorForm.comments,
          id: this.taskid,
          ProcessID: _errorForm.process
        }
        this.errorNotificationService.createErrorItem(addErrorNotificationService)
          .subscribe({
            next: () => {
              this.openToast(CommonMessages.Success, false)
              ReloadTaskBasicInfoEvent.emit();
              this.getAllErrorInfos();
              this.errorForm.reset();
            },
            error: (error: HttpErrorResponse) => {

              this.openToast(CommonMessages.Error, true);

              this.utilityService.setSpinner(false);
            },
            complete: () => { this.utilityService.setSpinner(false); this.hasChange = false; }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.timezoneSubscription?.unsubscribe();
  }

}
