<div class="main-container">
  <div class="content">
    <div class="actionbtn">
      Actions
    </div>
    <div class="label-input-labelwrapper">
      <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">

        <mat-select id="selectProdSystem" class="ig2" placeholder="Select Action" [(ngModel)]="selectedAction"
          (selectionChange)="onActionChange()"  [disabled]="!enableSubmit" >
          <mat-option [value]="item.value" *ngFor="let item of actionItem">
            {{ item.action }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <button id="btnAddUser" mat-flat-button
        [disabled]="!(selectedAction && (basicInfo.assignee != null) && (enableSubmit || (this.userinfo?.role?.id === 1000)) )"
        class="mat-focus-indicator ig2 button button--tertiary manage-widget-button mat-button mat-button-base"
        (click)="openAction()">
        Submit
      </button> -->
    </div>
    <div class="label-input-labelwrapper">
      <button id="btnAddUser" mat-flat-button
        class="mat-focus-indicator ig2 button button--tertiary manage-widget-button mat-button mat-button-base"
        (click)="openReportError()">
        <svg height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.823 18.11 13.134 3.63c-.503-.84-1.765-.84-2.268 0L2.177 18.11c-.504.838.127 1.89 1.134 1.89H20.69c1.007 0 1.637-1.052 1.133-1.89ZM12.002 8c.56 0 1.014.368.998.811l-.165 4.547c-.013.359-.384.642-.837.642-.454 0-.825-.287-.837-.642l-.16-4.547c-.013-.443.436-.811 1.001-.811ZM12 18a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            fill="#043B7C">

          </path>
          <path
            d="M21.823 18.11 13.134 3.63c-.503-.84-1.765-.84-2.268 0L2.177 18.11c-.504.838.127 1.89 1.134 1.89H20.69c1.007 0 1.637-1.052 1.133-1.89ZM12.002 8c.56 0 1.014.368.998.811l-.165 4.547c-.013.359-.384.642-.837.642-.454 0-.825-.287-.837-.642l-.16-4.547c-.013-.443.436-.811 1.001-.811ZM12 18a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
            fill="#043B7C"></path>
        </svg>
        Report Error
      </button>
    </div>
  </div>
</div>
