import { Assignee } from "./Assignee";

export interface FileStatus {
    id: number;
    value: string;
}

export interface TaskStatus {
    id: number;
    value: string;
}

    export interface TaskBasicInfo {
        id: string;
        externalSystemTaskId: number;
        branchName: string;
        branchOfficerName: string;
        transcationType: string;
        settlementDate: Date;
        dueDateUtc: Date;
        orderedDateUtc: Date;
        fastFileNumber: string;
        fileStatus: FileStatus;
        taskStatus: TaskStatus;
        assignee: Assignee;
        processId:any;
        productionSystemId: string;
        processHasOnshoreQueue: boolean;
        sourceId: number;
        emailId: string;
    }

export enum SourceApp{
    IgnitePS = 1,
    Ignite = 2
}