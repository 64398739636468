import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ErrorModel } from '../models/ErrorModel';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {

  readonly igpsBaseUrl = `${environment.igpsBase.apiUrl}`

  constructor(private httpClient: HttpClient) { }

  logApplicationErrors(error: ErrorModel) {
    return this.httpClient.post(`${this.igpsBaseUrl}` + `logger/log/v1`, error);
  }
}
