import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OpenDialogComponent } from '../app/components/open-dialog/open-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonService } from './services/common.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptor } from './interceptor/header-interceptor';
import { HttpClientModule } from '@angular/common/http';
import { ActionSectionComponent } from './components/action-section/action-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackToDashboardComponent } from './components/back-to-dashboard/back-to-dashboard.component';
import { ErrorInfoDialogComponent } from './components/error-info-dialog/error-info-dialog.component';
import { ToastModule } from '@ui-core/toast';
import { IgpsMessageComponent } from './components/shared/igps-message/igps-message.component';
import { PendingChangesGuard } from './directive/pending-changes.guard';
import { SpinnerModule } from '@igps/spinner';
import { FilterSelectModule } from '@igps/filter-select';
import { ConvertTimePipe, PipeModule } from '@igps/pipe';
import { DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    OpenDialogComponent,
    ActionSectionComponent,
    BackToDashboardComponent,
    ErrorInfoDialogComponent,
    IgpsMessageComponent,
    ConfirmDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    SpinnerModule,
    FilterSelectModule,
    PipeModule,
  ],
  providers: [PendingChangesGuard, CommonService, DatePipe,
    ConvertTimePipe, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: HttpInterceptor }],
  bootstrap: [AppComponent]
})
export class AppModule { }
